<template>
  <div class="Kngxqddf">
    <div class="t1">可能感兴趣的地方</div>
    <div class="content">
      <div class="contentdetail">
        <div class="adress">地名</div>
        <div class="people">1121人想去</div>
      </div>
      <div class="contentdetail">
        <div class="adress">地名</div>
        <div class="people">1121人想去</div>
      </div>
      <div class="contentdetail">
        <div class="adress">地名</div>
        <div class="people">1121人想去</div>
      </div>
      <div class="contentdetail">
        <div class="adress">地名</div>
        <div class="people">1121人想去</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.Kngxqddf {
  width: 1200px;
  height: 360px;
  /* background: pink; */
  margin: 0 auto;
}
.Kngxqddf .t1 {
  font-size: 37px;
  font-family: 59;
  font-weight: 400;
  color: #343434;
  line-height: 42px;
  margin-bottom: 35px;
}
.Kngxqddf .content {
  width: 100%;
  height: 360px;
  /* background: #bababa; */
  display: flex;
  justify-content: space-between;
}
.Kngxqddf .content .contentdetail {
  width: 284px;
  height: 284px;
  background: #bababa;
}
.Kngxqddf .content .contentdetail .adress {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #343434;
  line-height: 620px;
  text-align: center;
}
.Kngxqddf .content .contentdetail .people {
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  margin-top: -288px;
  text-align: center;
}
</style>