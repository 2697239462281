<template>
  <div class="zjktl-box">
    <div class="leftbox"></div>
    <div class="rightbox">
      <div class="title">张家口天路</div>
      <div class="name">（著名景点）</div>
      <div class="content">
        草原天路，位于张家口市张北县和崇礼区的交界处，西起尚义县城南侧的大青山（国家级森林公园），东至崇礼县桦皮岭处，是连接崇礼滑雪区、赤城温泉区、张北草原风景区、白龙洞风景区、大青山风景区的一条重要通道，也是中国大陆十大最美丽的公路之一。
        草原天路周边还分布着桦皮岭、野狐岭、古长城、玻璃吊桥（天路之巅）等旅游景点。
        [1] “草原天路”
        全长约323.9公里于2019年8月实现贯通（起自张承界，经赤城县、张北县、崇礼区、沽源县、万全区、尚义县，止于冀蒙界）
        [2]
        。公路沿线蜿蜒曲折、河流山峦、沟壑纵深、草甸牛羊、景观奇峻，展现出一幅百里坝头风景画卷，分布着古长城遗址、桦皮岭、野狐岭、张北草原等众多人文、生态和地质旅游资源。
      </div>
      <div class="detail">查看详情</div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.zjktl-box {
  width: 1200px;
  height: 397px;
  /* background: red; */
  margin: 82px auto 115px;
  display: flex;
  justify-content: space-between;
}
.zjktl-box .leftbox {
  width: 629px;
  height: 397px;
  background: #bababa;
}
.zjktl-box .rightbox {
  width: 496px;
  height: 397px;
  /* background: pink; */
  position: relative;
}
.zjktl-box .rightbox .title {
  width: 190px;
  height: 36px;
  font-size: 38px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #ce5a4e;
  line-height: 41px;
}
.zjktl-box .rightbox .name {
  width: 126px;
  height: 18px;
  font-size: 21px;
  font-family: 59;
  font-weight: 400;
  color: #343434;
  line-height: 23px;
  margin-left: 205px;
  margin-top: -22px;
}
.zjktl-box .rightbox .content {
  width: 496px;
  height: 187px;
  font-size: 14px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #343434;
  line-height: 22px;
  margin-top: 50px;
}
.zjktl-box .rightbox .detail {
  width: 133px;
  height: 47px;
  background: #ce5a4e;
  line-height: 47px;
  font-size: 25px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #ffffff;
  text-align: center;
  position: absolute;
  right: 0;
  bottom: 0;
}
</style>