<template>
  <div class="xcjs-box">
    <div class="t1">行程介绍</div>
    <div class="content">
      <div class="leftcontent"></div>
      <div class="line"></div>
      <div class="circle"></div>
      <div class="rightcontent">
        <div class="day">【第一天】</div>
        <div class="adress">地名</div>
        <div class="time">2019-06-02</div>
        <div class="t2">
          草原天路，位于张家口市张北县和崇礼区的交界处，西起尚义县城南侧的大青山（国家级森林公园），东至崇礼县桦皮岭处，是连接崇礼滑雪区、赤城温泉区、张北草原风景区、白龙洞风景区、大青山风景区的一
        </div>
        <div class="circle-box">
          <div class="cb">包含早餐</div>
          <div class="cb">包含午餐</div>
          <div class="cb">包含晚餐</div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="leftcontent"></div>
      <div class="line"></div>
      <div class="circle"></div>
      <div class="rightcontent">
        <div class="day">【第一天】</div>
        <div class="adress">地名</div>
        <div class="time">2019-06-02</div>
        <div class="t2">
          草原天路，位于张家口市张北县和崇礼区的交界处，西起尚义县城南侧的大青山（国家级森林公园），东至崇礼县桦皮岭处，是连接崇礼滑雪区、赤城温泉区、张北草原风景区、白龙洞风景区、大青山风景区的一
        </div>
        <div class="circle-box">
          <div class="cb">包含早餐</div>
          <div class="cb">包含午餐</div>
          <div class="cb">包含晚餐</div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="leftcontent"></div>
      <div class="line"></div>
      <div class="circle"></div>
      <div class="rightcontent">
        <div class="day">【第一天】</div>
        <div class="adress">地名</div>
        <div class="time">2019-06-02</div>
        <div class="t2">
          草原天路，位于张家口市张北县和崇礼区的交界处，西起尚义县城南侧的大青山（国家级森林公园），东至崇礼县桦皮岭处，是连接崇礼滑雪区、赤城温泉区、张北草原风景区、白龙洞风景区、大青山风景区的一
        </div>
        <div class="circle-box">
          <div class="cb">包含早餐</div>
          <div class="cb">包含午餐</div>
          <div class="cb">包含晚餐</div>
        </div>
      </div>
    </div>
    <div class="t3">
      *以上行程时间安排可能会因天气、路况等原因做相应调整，敬请谅解。
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.xcjs-box {
  width: 1200px;
  height: 941px;
  /* background: pink; */
  margin: 0px auto 108px;
}
.xcjs-box .t1 {
  width: 112px;
  height: 23px;
  font-size: 28px;
  font-family: 59;
  font-weight: 400;
  color: #343434;
  line-height: 23px;
  margin-bottom: 53px;
}
.xcjs-box .content {
  width: 800px;
  height: 231px;
  /* background: yellow; */
  position: relative;
  margin-bottom: 20px;
}
.xcjs-box .content .leftcontent {
  width: 390px;
  height: 231px;
  background: red;
}
.xcjs-box .content .line {
  width: 3px;
  height: 251px;
  background: #bababa;
  position: absolute;
  top: 0px;
  left: 431px;
}
.xcjs-box .content .circle {
  width: 19px;
  height: 19px;
  background: #bababa;
  border-radius: 50%;
  position: absolute;
  top: 20px;
  left: 424px;
}
.xcjs-box .content .rightcontent {
  width: 363px;
  height: 231px;
  /* background: fuchsia; */
  position: absolute;
  left: 477px;
  top: 0;
}
.xcjs-box .content .rightcontent .day {
  width: 155px;
  height: 30px;
  font-size: 31px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #343434;
  line-height: 43px;
  margin-left: -20px;
}
.xcjs-box .content .rightcontent .adress {
  width: 103px;
  height: 29px;
  font-size: 31px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #343434;
  line-height: 43px;
  margin-left: 279px;
  margin-top: -26px;
}
.xcjs-box .content .rightcontent .time {
  width: 106px;
  height: 13px;
  font-size: 19px;
  font-family: 58;
  font-weight: 400;
  color: #343434;
  line-height: 43px;
  margin-top: 9px;
}
.xcjs-box .content .rightcontent .t2 {
  width: 363px;
  height: 51px;
  font-size: 14px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #343434;
  line-height: 19px;
  margin-top: 22px;
}
.xcjs-box .content .rightcontent .circle-box {
  width: 420px;
  height: 38px;
  /* background: green; */
  position: absolute;
  bottom: 30px;
  left: 0;
  display: flex;
  justify-content: space-between;
}
.xcjs-box .content .rightcontent .circle-box .cb {
  width: 121px;
  height: 38px;
  border: 1px solid #a0a0a0;
  border-radius: 15px;
  line-height: 38px;
  text-align: center;
  font-size: 19px;
  font-family: 58;
  font-weight: 400;
  color: #343434;
  line-height: 43px;
}
.xcjs-box .t3 {
  font-size: 19px;
  font-family: 58;
  font-weight: 400;
  color: #343434;
  line-height: 43px;
  margin-top: 97px;
}
</style>