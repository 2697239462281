<template>
  <div class="more-box">
    <Swiper></Swiper>
    <Zjktl></Zjktl>
    <Xcjs></Xcjs>
    <Kngxqddf></Kngxqddf>
  </div>
</template>

<script>
import Swiper from "../swiper/swiper";
import Zjktl from "../zjktl/zjktl";
import Xcjs from "../xcjs/xcjs";
import Kngxqddf from "../kngxqddf/kngxqddf";
export default {
  components: {
    Swiper,
    Zjktl,
    Xcjs,
    Kngxqddf
  },
};
</script>

<style>
.more-box{
    width: 100%;
    height: 3000px;
    /* background: chartreuse; */
}
</style>